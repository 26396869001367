@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  ul,
  ol,
  menu {
    list-style-type: initial;
  }

  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    display: inherit;
    vertical-align: inherit;
  }

  :root {
    background-color: inherit;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: white;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #DBDBDB;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #DBDBDB;
  }
}

@layer components {
  .tw-btn {
    @apply tw-box-border tw-outline-1 focus:tw-border-none focus:tw-outline-none tw-rounded tw-px-4 tw-py-2 tw-font-semibold tw-text-base;
  }

  .tw-btn-primary {
    @apply tw-text-white;
  }

  .tw-btn-unofficial-green {
    @apply tw-bg-unofficial-green tw-text-white;
  }

  .tw-tab-active {
    @apply tw-font-bold;
  }

  .tw-shadow-200 {
    box-shadow: 0px 8px 16px 0px rgba(20, 20, 21, 0.08);
  }

  .tw-modal-box {}

  .tw-modal {
    @apply md:tw-min-w-[25%] tw-rounded-lg tw-py-4 tw-px-4 max-[600px]:tw-mx-4;
    max-height: 80%;
    top: calc(10vh);
    align-items: start;
  }

  @media (max-width: 600px) {
    .tw-modal {
      @apply tw-min-w-[90%];
    }
  }

  .tw-modal {
    top: calc(10vh);
    align-items: start;
  }

  .tw-font-proxima-regular {
    @apply tw-font-normal;
    font-family: "proximasoft";
    font-style: 'regular';
  }

  .tw-font-proxima-medium {
    @apply tw-font-medium;
    font-family: "proximasoft";
    font-style: 'medium';
  }

  .tw-font-proxima-semibold {
    @apply tw-font-semibold;
    font-family: "proximasoft";
    font-style: 'semibold';
  }

  .tw-dietary-container {
    a {
      width: 20px;
      height: 20px;
    }

    svg {
      width: 20px;
      height: 20px;
    }

    .badges__icon {
      margin-left: 0px;
      margin-top: 0px;
    }
  }

  .tw-svg-hover:hover {
    svg {
      path {
        @apply tw-fill-broccoli-600;
      }
    }
  }

  .tw-badge {
    @apply tw-px-3 tw-py-1 tw-text-sm tw-text-gray-900 tw-rounded-full;
  }

  .tw-badge-success {
    @apply tw-badge tw-border tw-border-broccoli-600 tw-bg-broccoli-200;
  }

  .tw-badge-error {
    @apply tw-badge tw-border tw-border-strawberry-300 tw-bg-strawberry-200;
  }

  .tw-modal[open] {
    @apply !tw-flex;
  }

  .tw-modal[open]::backdrop {
    @apply tw-bg-gray-900/50;
  }

  .react-confirm-alert-overlay,
  .overlay-custom-alert-box {
    @apply !tw-bg-gray-900/50;
  }
}

@layer utilities {
  .tw-scrollable {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .tw-scrollable::-webkit-scrollbar {
    display: none;
    background: transparent;
  }
}
